import { server } from "@/code/server";
import * as models from "@/models";
import { G } from "@/globals";

export class lookups {
  static getJobPostById = (jpId: number) => {
    return server
      .Post<models.jobPost>("lookups/getJobPostById", { id: jpId }, false)
      .then(jp => new models.jobPostExtended(jp));
  };

  static industries = (parentId?: number) => {
    return server.Get<models.industry[]>(`lookups/industries`, {
      parentId: parentId,
      nested: !parentId
    });
  };
  static industries_Cached = async () => {
    let result = await G.localCommonDb.db().industries.toArray();
    if (false && result.length > 0) {
      return result;
    } else {
      return lookups.industries(null).then(inds => {
        G.localCommonDb
          .db()
          .industries.clear()
          .then(() => {
            G.localCommonDb.db().industries.bulkPut(inds);
          });

        return inds;
      });
    }
  };

  static languages = () => {
    return server.Get<models.language[]>("lookups/languages");
  };

  static languages_Cached = async () => {
    let result = await G.localCommonDb.db().languages.toArray();
    if (result.length > 0) {
      return result;
    } else {
      return lookups.languages().then(lang => {
        G.localCommonDb
          .db()
          .languages.clear()
          .then(() => {
            G.localCommonDb.db().languages.bulkPut(lang);
          });

        return lang;
      });
    }
  };

  static countries = () => {
    return server.Get<models.country[]>("lookups/countries");
  };
  static cities = (countryCode: string) => {
    return server.Get<models.country>("lookups/cities", {
      countryCode: countryCode
    });
  };
}
