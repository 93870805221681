import { server } from "@/code/server";
import * as models from "@/models";
import { G } from "@/globals";
import { accounts } from "./api.accounts";

export namespace jobs.seekers {
  export class labels {
    static applyForJob = (jobPostId: number) => {
      return server.Post<models.seekerLabel>(
        "jobs/seekers/labels/apply",
        { jobPostId },
        true
      );
    };
    static unApplyForJob = (jobPostId: number) => {
      return server
        .Post<models.seekerLabel>(
          "jobs/seekers/labels/apply",
          { jobPostId, removeLabel: true },
          true
        )
        .then(() => {
          return <models.seekerLabel>null;
        });
    };
    static bookmark = (jobPostId: number) => {
      return server.Post<models.seekerLabel>(
        "jobs/seekers/labels/bookmark",
        { jobPostId },
        true
      );
    };
    static unBookmark = (jobPostId: number) => {
      return server
        .Post<models.seekerLabel>(
          "jobs/seekers/labels/bookmark",
          { jobPostId, removeLabel: true },
          true
        )
        .then(() => {
          return <models.seekerLabel>null;
        });
    };
  }
  export class posts {
    static getPublished_Paged = (
      industryId: number,
      skip: number,
      take: number
    ) => {
      return server
        .Post<models.paged<models.jobPost>>(
          "jobs/seekers/posts/getPublishedByIndustry",
          { industryId: industryId, skip: skip, take: take },
          true
        )
        .then(pages => {
          let result = <models.paged<models.jobPostExtended>>{
            hasNext: pages.hasNext,
            hasPrevious: pages.hasPrevious,
            items: pages.items.map(jp => new models.jobPostExtended(jp)),
            skipped: pages.skipped,
            taken: pages.taken
          };
          return result;
        });
    };
    static getPublished_Paged__NoUser = (
      industryId: number,
      skip: number,
      take: number
    ) => {
      return server
        .Post<models.paged<models.jobPost>>(
          "jobs/seekers/posts/getPublishedByIndustry",
          { industryId: industryId, skip: skip, take: take }
        )
        .then(pages => {
          let result = <models.paged<models.jobPostExtended>>{
            hasNext: pages.hasNext,
            hasPrevious: pages.hasPrevious,
            items: pages.items.map(jp => new models.jobPostExtended(jp)),
            skipped: pages.skipped,
            taken: pages.taken
          };
          return result;
        });
    };
  }
  export class videos {
    static upload = (
      file: File,
      progressHandler: (percentage: number) => void
    ) => {
      let parts = file.name.split(".");
      let extension = parts[parts.length - 1];

      return server.PostMedia<models.seekerVideo>(
        "jobs/seekers/videos/upload",
        {
          extension: extension
        },
        file,
        true,
        progressHandler
      );
    };
    static getMyVideos = () => {
      return server.Post<models.seekerVideo[]>(
        "jobs/seekers/videos/getMyVideos",
        {},
        true
      );
    };
  }

  export class profiles {
    static getProfile = (id: number, includeVideos: boolean) => {
      let forOrgId: number = accounts.currentOrgId;
      return server.Post<models.seekerProfile>(
        "jobs/seekers/profiles/getProfile",
        {
          id: id,
          includeVideos: includeVideos,
          forOrgId
        },
        false
      );
    };
    static getProfiles_Paged = async (
      industryId: number,
      skip: number,
      take: number
    ) => {
      let forOrgId: number = accounts.currentOrgId;
      return server
        .Post<models.paged<models.seekerProfile>>(
          "jobs/seekers/profiles/getProfiles",
          {
            industryId,
            skip,
            take,
            forOrgId
          },
          true
        )
        .then(profiles => {
          // let result = <models.paged<models.jobPostExtended>>{
          //   hasNext: pages.hasNext,
          //   hasPrevious: pages.hasPrevious,
          //   items: pages.items.map(jp => new models.jobPostExtended(jp)),
          //   skipped: pages.skipped,
          //   taken: pages.taken
          // };
          // return result;
          return profiles;
        });
    };

    static basicSearchProfiles_Paged = async (
      query: string,
      skip: number,
      take: number
    ) => {
      return server
        .Post<models.paged<models.seekerProfile>>(
          "jobs/seekers/profiles/basicSearch",
          {
            query,
            skip,
            take
          },
          true
        )
        .then(profiles => {
          return profiles;
        });
    };

    static searchProfiles_Paged = async (
      query: string,
      skip: number,
      take: number,
      filter_IndustryIds: number[],
      filter_sectorIds: number[],
      filter_CountryCodes: string[],
      filter_CityIds: string[]
    ) => {
      if (filter_sectorIds && filter_sectorIds.length > 0)
        filter_IndustryIds = null;
      if (filter_CityIds && filter_CityIds.length > 0)
        filter_CountryCodes = null;

      return server
        .Post<models.paged<models.seekerProfile>>(
          "jobs/seekers/profiles/search",
          {
            query,
            skip,
            take,
            filter_IndustryIds,
            filter_sectorIds,
            filter_CountryCodes,
            filter_CityIds
          },
          true
        )
        .then(profiles => {
          return profiles;
        });
    };

    static update = (profile: models.seekerProfile) => {
      return server.Post<models.seekerProfile>(
        "jobs/seekers/profiles/updateProfile",
        {
          industryId: profile.industry.id,
          cityId: profile.city.id,
          headline: profile.headline,
          bio: profile.bio,
          jobTypes: profile.jobTypes,
          firstname: profile.firstname,
          lastname: profile.lastname,
          currentlyOpen: profile.currentlyOpen,
          languages: profile.languages.map(l => l.code)
        },
        true
      );
    };
  }
}
